<!--
 * @Author: 何家豪 hejiahao_tech@163.com
 * @Date: 2023-12-29 14:25:14
 * @LastEditors: hejiahao_tech@163.com hejiahao_tech@163.com
 * @LastEditTime: 2024-07-25 15:39:17
 * @FilePath: \chrome-extensiond:\workSpace\demo\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- 返回上一页 -->
  <div class="backGo">
        <!-- 返回键 -->
        <!-- <span class="backGoBtn" @click="goBack"></span> -->
        <span>
          Wireless Query
        </span>
    </div>
    <div class="container">
        <div class="selectInfo">
          <span class="title">Check if support CarPlay/Android Auto</span>
            <div class="select-extrnal">
              <div>
                <div class="content">
                    <span>
                      <Aselect 
                        v-model:value="formObj.brandValue"
                        placeholder="Select Make"   
                        :show-search="false"
                        :show-arrow="true"
                        style="width: 200px"
                        :options="options"
                        :filter-option="filterOption"
                        @blur="handleBlur"
                        @change="handleChange"
                      />
                    </span>
                </div>
              </div>
              <div>
                <div class="content">
                    <span>
                      <Aselect 
                        v-model:value="formObj.vehicleModel"
                        placeholder="Select Model"
                        :show-search="false"
                        style="width: 200px"
                        :options="vehicleOptions"
                        :disabled="isVehicleDisabled"
                        popup-class-name="popupClassName"
                        :filter-option="filterOption"
                        @focus="() => focusScrollTop(1)"
                        @change="vehicleModelChange"
                      />
                    </span>
                </div>
              </div>
              <div>
                <div class="content">
                    <span>
                      <Aselect
                        v-model:value="formObj.yearManufacture"
                        placeholder="Year"
                        :show-search="false"
                        style="width: 200px"
                        :options="yearOptions"
                        :filter-option="filterYearOption"
                        :disabled="isYearDisabled"
                        @focus="() => focusScrollTop(2)"
                        @change="yearChange"
                      />
                    </span>
                </div>
              </div>
            </div>
            <span>
              <Abutton @click="submit" :loading="confirmLoading">confirm</Abutton>
            </span>
        </div>
        <div class="productInfo">
          <a-spin :spinning="isSpin">
          <span v-if="isProduct">
            <img :src="productImg" alt="">
          </span>
          <span v-else>
            <img v-if="isSuccess" src="https://thumbsnap.com/i/p8BiMdcD.jpg" alt="">
            <img v-else src="https://thumbsnap.com/i/ic35MgcZ.jpg" alt="">
          </span>
        </a-spin>
        </div>
        <div class="bottomBtn">
            <span>
                <Abutton v-if="isBuyOnAamzon && asin && asin.trim()" class="btn-style" @click="goPurchase" :loading="buyLoading">Buy on Aamzon</Abutton>
                <!-- <Abutton v-if="isBack" class="btn-style" @click="goBack">Back</Abutton> -->
            </span>
        </div>
    </div>
</template>

<script setup>
  import { ref, onBeforeMount, onMounted } from 'vue'
  import { Select as Aselect, Button as Abutton, message, Spin as aSpin } from 'ant-design-vue'
  let asin = ref(null)
  let isSpin = ref(false)
  let confirmLoading = ref(false)
  let buyLoading = ref(false)
  let productImg = ref('https://thumbsnap.com/i/bN9JXU5e.jpg')
  asin.value = (new URLSearchParams(new URL(window.location.href).search)).get('asin')
  if (asin.value) {
    asin.value = asin.value.trim()
  }
  // 车型
  let options = ref([])
  options.value = []
  // 获取车品牌
  const getMake = function () {
    fetch('/api/carplay/getBrand', {
      method: 'GET'
    }).then(res => res.json()).then(res => {
      // console.log('res', res)
      options.value = []
      let _list = res
      _list.sort((m, n) => {
        let _m = m.value.toLowerCase()
        let _n = n.value.toLowerCase()
        if (_m[0] < _n[0]) {
          return -1
        } else if (_m[0] > _n[0]) {
          return 1
        } else {
          if (_m.length > _n.length) {
            return 1
          } else if (_m.length < _n.length) {
            return -1
          } else {
            return 0
          }
        }
        
      })
      let _map = new Map()
      _list.map(item => {
          let obj = {label: item.value, value: item.id}
          if (!_map.get(item.id)) {
            _map.set(item.id, item.value)
            options.value.push(obj)
          }
        })
    }).catch(() => {
      options = []
    })
  }
  onBeforeMount(() => getMake())
  onMounted(() => (async function () {
    const userAgent = navigator.userAgent
    const flag = userAgent.includes('ChannelName')
    if (flag) {
      document.querySelector('div.backGo').style.cssText = 'display: none'
    } else {
      document.querySelector('div.backGo').style.cssText = 'display: flex'
    }
    if (asin.value) {
      if (asin.value === 'B0CTH3S7LH') {
        productImg.value = 'https://thumbsnap.com/i/LZTz7VSS.jpg'
      } else if (asin.value === 'B0CDC5HHWZ') {
        productImg.value = 'https://thumbsnap.com/i/bN9JXU5e.jpg'
      } else if (asin.value === 'B0CT4SMMFB') {
        productImg.value = 'https://thumbsnap.com/i/ADGKjmAQ.jpg'
      } else {
        productImg.value = 'https://thumbsnap.com/i/bbVbXbXE.jpg'
      }
    } else {
      productImg.value = 'https://thumbsnap.com/i/bbVbXbXE.jpg'
    }
  })())

  let isProduct = ref(true)
  let isSuccess = ref(true)
  let isBuyOnAamzon = ref(false)
  let isBack = ref(false)
  let isVehicleDisabled = ref(true)
  let isYearDisabled = ref(true)
  // 车辆生产日期  
  let yearOptions = ref([])
  // 车辆型号
  let vehicleOptions = ref([])
  // 参数对象
  const formObj = ref({
    brandValue: null,
    yearManufacture: null,  
    vehicleModel: null
  })
  // 获取车型
  const getModel = function () {
    fetch('/api/carplay/getModel?brandId=' + formObj.value.brandValue, {
      method: 'GET'
    }).then(res => res.json()).then(res => {
      vehicleOptions.value = []
      let _list = res
      _list.sort((m, n) => {
        let _m = m.value.toLowerCase()
        let _n = n.value.toLowerCase()
        if (_m[0] < _n[0]) {
          return -1
        } else if (_m[0] > _n[0]) {
          return 1
        } else {
          if (_m.length > _n.length) {
            return 1
          } else if (_m.length < _n.length) {
            return -1
          } else {
            return 0
          }
        }
        
      })
      _list.map(item => {
          let obj = {label: item.value, value: item.id, key: item.value}
          vehicleOptions.value.push(obj)
        })
    }).catch(err => {
      vehicleOptions = err
    })
  }
  // 获取车型相关的出厂年份
  const getYearList = function () {
    fetch(`/api/carplay/getYear?brandId=${formObj.value.brandValue}&modelId=${formObj.value.vehicleModel}`, {
      method: 'GET'
    }).then(res => res.json()).then(res => {
      yearOptions.value = []
      let _list = []
      _list = res.sort((n, m) => Number(m.value) - Number(n.value))
      _list.map(item => {
        let obj = {label: item.value, value: item.id, key: item.value}
        yearOptions.value.push(obj)
      })
    }).catch(() => {
      yearOptions = []
    })
  }
  const handleChange = () => { 
    isProduct.value = true
    isSuccess.value = false
    isBuyOnAamzon.value = false
    if (!formObj.value.brandValue) {
      isVehicleDisabled.value = true
    } else {
      isVehicleDisabled.value = false
      getModel()
    }
    formObj.value.vehicleModel = null
    formObj.value.yearManufacture = null
    yearOptions.value = []   
    isYearDisabled.value = true
  }
  // 
  const goPurchase = function () {
    // fetch('/api/carplay/getAsin', {
    //   method: 'GET'
    // }).then(res => res.text()).then(res => {
    //   window.location.href = 'https://amazon.com/dp/' + res
    // }).catch(err => {
    //   message.error(err.msg)
    // })
    buyLoading.value = true
    let str = ''
    let _params = (new URLSearchParams(new URL(window.location.href).search))
    for (const [key, value] of _params) {
      if (key != 'asin') {
        str += `${key}=${value}&`
      }
    }
    // 去除最后位置的 & 符号
    str = str.substr(0, str.length - 1)
    window.location.href = 'https://amazon.com/dp/' + asin.value + '?' + str
    buyLoading.value = false
  }
  // 返回上一页
  // const goBack = function () {
  //   if (window.history.length > 1) {
  //     window.history.back()
  //   }
  // }
  // 车型更改
  const vehicleModelChange = () => {
    isProduct.value = true
    isSuccess.value = false
    isBuyOnAamzon.value = false
    if (!formObj.value.vehicleModel) {
      isYearDisabled.value = true
    } else {
      isYearDisabled.value = false
      getYearList()
    }
    formObj.value.yearManufacture = null
  }
  // 车型选择获取焦点
  const focusScrollTop = (val) => {
    let setInt = setInterval(() => {
      let _ele = document.querySelectorAll('.ant-select-dropdown .rc-virtual-list-holder')[val]
      if (_ele) {
        _ele.scrollTo(0, 0)
        clearInterval(setInt)
      }
    }, 60);
  }
  const yearChange = () => {
    isProduct.value = true
    isSuccess.value = false
    isBuyOnAamzon.value = false
  }
  const handleBlur = () => {}
  // 搜索过滤
  const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
  // 搜索过滤
  const filterYearOption = (input, option) => {
    console.log(option)
    return String(option.label).includes(input)
  }
  const submit = function () {
    if (!formObj.value.brandValue) {
      message.error('Vehicle Make cannot be empty')
      return
    }
    if (!formObj.value.vehicleModel) {
      message.error('Vehicle model cannot be empty')  
      return
    }
    if (!formObj.value.yearManufacture) {
      message.error('Vehicle production year cannot be empty')
      return
    }
    confirmLoading.value = true
    isBuyOnAamzon.value = false
    isBack.value = false
    isSpin.value = true
    fetch(`/api/carplay/checkSupport?brandId=${formObj.value.brandValue}&modelId=${formObj.value.vehicleModel}&yearId=${formObj.value.yearManufacture}`, {
      method: 'GET'
    }).then(res => res.json()).then(res => {
      if (res) {
        isSuccess.value = res
        setTimeout(() => {
          confirmLoading.value = false
          isProduct.value = false
          isBuyOnAamzon.value = true
          isBack.value = false
        }, 100);
      } else {
        isSuccess.value = false
        setTimeout(() => {
          confirmLoading.value = false
          isProduct.value = false
          isBuyOnAamzon.value = false
          isBack.value = true
        }, 100);
      }
      setTimeout(function () {
        isSpin.value = false
        window.scrollTo(0, document.body.scrollHeight)
      }, 200)
    }).catch(() => {
      isProduct.value = false
      isSuccess.value = false
    })
  }
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@font-face {
  font-family: "阿里妈妈数黑体 Bold";font-weight: 700;src: url("//at.alicdn.com/wf/webfont/X9v7R4fjMDgP/cxfiLLkmz5Ot.woff2") format("woff2"),
  url("//at.alicdn.com/wf/webfont/X9v7R4fjMDgP/We4aRbU8knYg.woff") format("woff");
  font-display: swap;
}
div.backGo {
  font-family: "阿里妈妈数黑体 Bold";font-weight: 700;src: url("//at.alicdn.com/wf/webfont/X9v7R4fjMDgP/cxfiLLkmz5Ot.woff2") format("woff2"),
  url("//at.alicdn.com/wf/webfont/X9v7R4fjMDgP/We4aRbU8knYg.woff") format("woff");
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  color: #2c3e50;
  height: 3rem;
  background: #fff;
  justify-content: center;
  margin: auto 0;
  display: flex;
  align-items: center;
  font-family: "阿里妈妈数黑体 Bold";
  font-weight: 700;
  position: sticky;
  top: 0px;
  z-index: 999;
}
div.backGo span.backGoBtn {
  display: block;
  position: absolute;
  left: 1rem;
  cursor: pointer;
}
div.backGo span.backGoBtn::before {
  content: '';
  display: block;
  position: absolute;
  height: 3px;
  width: 15px;
  transform: rotateZ(135deg) translateY(6px);
  background: #1a92e9;
}
div.backGo span.backGoBtn::after {
  content: '';
  display: block;
  position: absolute;
  height: 3px;
  width: 14px;
  transform: rotateZ(42deg) translateY(6px);
  background: #1a92e9;
}
#app {
  font-family: Avenir,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  color: #2c3e50;
  margin: auto;
  background: #eeeeee;
  height: 100vh;
  display: block;
  position: relative;
}
#app > div.container > div.selectInfo > div > div {
    height: 5rem;
    width: calc(100% - 2rem);
    margin: 0.8rem;
    border-radius: 17px;
    background: linear-gradient(141deg, #eff7fe, #4251fd,  #4251fd, #eff7fe);
    padding: 3px;
    border: 1px solid #cfcfcf;
}
#app > div.container > div.selectInfo > div > div > div.content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(94deg, #377cde 55%, #010408);
  height: 100%;
  border-radius: 15px;
  box-shadow: 6px 6px 10px #3d7bef
}
#app > div.container > div.selectInfo > div > div > div.content span {
  display: block;
  width: 100%;
  height: 4rem;
}
#app > div.container > div.selectInfo > div > div > div.content span div {
  height: 100%;
  width: 100%;
  line-height: 100px;
}
#app > div.container > div.selectInfo > div > div > div.content span div div.ant-select-selector {
  height: 100%;
}
#app > div.container > div.selectInfo > div > div > div > span > div {
  width: calc(100% - 2rem) !important;
}
#app > div.container > div.selectInfo > div > div > div > span > div > div > span.ant-select-selection-item {
    left: 2.3rem;
    text-align: left;
    font-size: 20px;
    margin: 0 auto;
    line-height: 310%;
    height: 100%;
    color: #fff;
    padding-right: 4.1rem;
}
#app > div.container > div.selectInfo > div > div > div > span > div > span > span {
  margin: 0 auto;
}
#app > div.container > div.selectInfo > div > div > div > span > div > span > span > svg {
  font-size: 3rem;
  color: #fff;
}
#app > div.container > div.selectInfo > div > div > div > span > div > div.ant-select-selector {
  background: #000;
}
#app > div.container > div.selectInfo > div > div > div > span > div > span {
  height: 3rem !important;
  width: 3rem !important;
  top: 1rem;
  bottom: 1rem;
}
#app > div.container > div.selectInfo > div > div:nth-child(1) > div > span > div > div.ant-select-selector::before {
    content: '1';
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    background: #181818;
    z-index: 1;
    font-size: 3rem;
    color: #fff;
    border-radius: 50%;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 43px;
    font-weight: 700;
    border: 1px solid #fff;
    border-bottom: 0px;
}
#app > div.container > div.selectInfo > div > div:nth-child(2) > div > span > div > div.ant-select-selector::before {
    content: '2';
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    background: #181818;
    z-index: 1;
    font-size: 3rem;
    color: #fff;
    border-radius: 50%;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 43px;
    font-weight: 700;
    border: 1px solid #fff;
    border-bottom: 0px;
}
#app > div.container > div.selectInfo > div > div:nth-child(3) > div > span > div > div.ant-select-selector::before {
    content: '3';
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    background: #181818;
    z-index: 6;
    font-size: 3rem;
    color: #fff;
    border-radius: 50%;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 43px;
    font-weight: 700;
    border: 1px solid #fff;
    border-bottom: 0px;
}
#app > div.container > div.selectInfo > div > div:nth-child(1) > div > span > div > div.ant-select-selector::after {
    content: '';
    display: block;
    position: absolute;
    width: 52px;
    height: 52px;
    background: red;
    z-index: 3;
    font-size: 3rem;
    color: #fff;
    border-radius: 50%;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 47px;
    font-weight: 700;
}
#app > div.container > div.selectInfo {
  font-family: Avenir,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: calc(100% - 2.4rem);
  margin: 0 1.2rem;
  background: linear-gradient(179deg, #d3f5fe, white);
  padding-bottom: 1.3rem;
  border-radius: 0 0 6rem 6rem;
}
#app > div.container > div.selectInfo > span:nth-child(3) {
  display: block;
  height: 2rem;
}
#app > div.container > div.selectInfo > span:nth-child(3) > button {
  font-size: 1rem;
  height: 2.4rem;
  line-height: 0rem;
  background: #000;
  color: #fff;
  font-weight: 600;
  padding-bottom: 0.4rem;
}
#app > div.container > div.selectInfo > span.title {
  color: #2c3e50;
  text-align: left;
  display: block;
  text-align: center;
  font-weight: 600;
  padding-top: 1rem;
}
#app > div.container > div.productInfo > div > div.ant-spin-container > span {
  width: 100%;
  height: 100%;
  display: block;
}
#app > div.container > div.productInfo > div > div.ant-spin-container > span > img {
  display: block;
  width: inherit;
}
#app > div.container > div.selectInfo > div > div > div > span > div > div > span.ant-select-selection-placeholder {
  color: rgba(255, 255, 255, 44%);
  margin: auto;
  font-size: 1.5rem;
  height: 4rem;
  line-height: 3.5rem;
}
#app > div.container > div.selectInfo > div > div > div > span > div > span.ant-select-clear > span > svg {
  position: absolute;
  left: 0;
  background: #000;
}
#app > div.container > div.selectInfo > div > div > div > span > div.ant-select-open > span.ant-select-arrow > span > svg.anticon-down {
  position: absolute;
  left: 0;
  background: transparent;
  z-index: 10;
  transform: rotateZ(-180deg);
  transition: all 0.6s;
}
#app > div.container > div.bottomBtn {
  background: #fff;
  padding-bottom: 12rem;
  height: 33px;
}
#app > div.container > div.bottomBtn > span > button {
  background: #FFA41C;
  color: #000;
  border-color: #FF8F00;
  font-weight: 700;
  border-width: 0.1rem;
}
#app > div.container > div.selectInfo > div > div > div > span > div.ant-select-disabled .ant-select-selection-placeholder {
  color: rgb(148, 162, 184);
  font-size: 1.5rem;
  margin: auto;
}
#app > div.container > div.selectInfo > div > div > div > span > div.ant-select-disabled .ant-select-selector {
  background: #eef1f6;
}
#app > div.container > div.selectInfo > div > div > div > span > div.ant-select-disabled span svg {
  color: rgb(148 162 184);
}
input {
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
#app > div.container > div.selectInfo > div > div > div > span > div > div > span.ant-select-selection-search input {
  display: block;
  height: 100%;
  position: relative;
  left: 2.1rem;
  font-size: 20px;
  color: #fff;
}
</style>
